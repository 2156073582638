import { CarStockOriginEnum } from '@/core/enum/car-stock-origin.enum';

export const carStockOriginPipe = (status: number) => {
  switch (status) {
    case CarStockOriginEnum.Own:
      return 'Próprio';
    case CarStockOriginEnum.Sublease:
      return 'Sublocação';
    case CarStockOriginEnum.SubAcquisition:
      return 'Subaquisição';
  }
}
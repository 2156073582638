export enum CarRequestStatusEnum {
  PendingDocumentation = 0,
  Allowed = 1,
  Denied = 2,
  RequireChanges = 3,
  Pending = 4,
  LinkSent = 5,
  CarDelivered = 6,
  Canceled = 7,
  Paid = 8,
  FinancialApproved = 9,
  FinancialReproved = 10,
  DeliveryDateDefined = 11,
  LinkExpired = 12,
  PaymentFail = 13,
  ReversedPayment = 14,
  ReversedInstallment = 15,
  TransportAuthorizationSent = 16,
  BilletGenerated = 17,
  InProgress = 18,
  Extended = 19,
  ChassisChangeRequested = 20,
  ChassisChangePreparation = 21,
  ChassisChangeContratualAmendment = 22,
  ChassisChangeDevolution = 23,
  ApproveWithoutPayment = 24
}
import { TrafficTicketStatusEnum } from '@/core/enum/traffic-ticket-status.enum';

export const trafficTicketStatusPipe = (status: TrafficTicketStatusEnum): string => {
  switch (status) {
    case TrafficTicketStatusEnum.Opened:
      return 'Em aberto';
    case TrafficTicketStatusEnum.Paid:
      return 'Pago';
    default:
      return '-';
  }
};
import { createApp } from 'vue';
import { Quasar, Notify, Dialog } from 'quasar';
import quasarLang from 'quasar/lang/pt-BR';
import { createPinia } from 'pinia';
import quasarIconSet from 'quasar/icon-set/svg-fontawesome-v6';
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import App from './App.vue';
import keycloakService from './auth/key-cloak-service'
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css';
import 'shepherd.js/dist/css/shepherd.css';
import 'quasar/src/css/index.sass';
import momentPlugin from '@/core/plugins/moment';
import datePlugin from '@/core/plugins/date-fns';

import VueShepherd from 'vue-shepherd';



import gosystem from './index';

import formatCpfCnpj from '@/core/pipe/CpfCnpjPipe'

const pinia = createPinia();
const app = createApp(App);
app.use(pinia)

app.use(Quasar, {
  plugins: { Notify, Dialog },
  lang: quasarLang,
  iconSet: quasarIconSet,
});

app.use(VueShepherd)
app.use(gosystem, {});
app.use(momentPlugin);
app.use(datePlugin);
app.use(Vue3Lottie, { name: "Vue3Lottie" });

app.mixin({
  created() {
    this.$keyCloakService = keycloakService;
  },
})
keycloakService.init().then(() => {
  app.mount('#app');
})

app.config.globalProperties.$formatCpfCnpj = formatCpfCnpj;

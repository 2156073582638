import { ClaimBudgetStatusEnum } from '@/core/enum/claim-budget-status.enum';

export const claimBudgetStatusPipe = (status: number) => {
  switch (status) {
    case ClaimBudgetStatusEnum.Opened:
      return 'Em aberto';
    case ClaimBudgetStatusEnum.Unauthorized:
      return 'Não autorizado';
    case ClaimBudgetStatusEnum.Authorized:
      return 'Autorizado';
  }
}
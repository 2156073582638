import { menuStore } from './menu-store';
import { actions, generateRoute } from './menu-navigation';

const menu = {
	install(vue: any, parameters: { routeActions?: any; registerMenu?: any; store?: any; putComponent?: any; }) {
		const stores = menuStore();
		const { store, putComponent } = parameters;
		putComponent('menu-component', () => import('./menu.vue'));
  	putComponent('menu-item-component', () => import('./menu-item.vue'));		
		putComponent('breadcrumbs', () => import('./breadcrumbs.vue'));

		parameters.routeActions = actions;

		parameters.registerMenu = function (menu: any, params: any, legacyMenu: any) {
			stores.register(menu);
			generateRoute(menu, params);
		};
	}
};

export default menu;

import core from './core'
import trafficTicket from './modules/traffic-ticket';
import fleets from './modules/fleets';
import home from './modules/main';
import charges from './modules/charges';
import monthlyPayments from './modules/monthly-payments';

const gosystem = {
  install(vue, parameters) {
    const args = arguments;

    parameters = parameters || {}
    parameters.gosystem = {};
    parameters.modules = parameters.modules || [];
    parameters.beforeInit = parameters.beforeInit || [];
    const optModules = [fleets, trafficTicket, home, charges, monthlyPayments]
    const modules = [core, ...optModules]
    delete parameters.modules
    for (const module of modules) {
      vue.use(module, parameters)
    }

    let beforeInit;
    if (parameters.beforeInit.length !== 0) {
      beforeInit = parameters.beforeInit;
      delete parameters.beforeInit;
    }
    if (beforeInit && beforeInit.length) {
      beforeInit.forEach((fn) => {
        if (typeof fn === 'function') {
          fn.apply(this, args);
        }
      });
    }
    parameters.vm = vue;
  }
}

export default gosystem

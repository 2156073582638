import { CarStatusEnum } from '@/core/enum/car-status.enum';

export const carStatusPipe = (status: CarStatusEnum): string => {
  switch (status) {
    case CarStatusEnum.Available:
      return 'Disponível';
    case CarStatusEnum.Reserved:
      return 'Reservado';
    case CarStatusEnum.Release:
      return 'Liberado';
    case CarStatusEnum.Delivered:
      return 'Entregue';
    case CarStatusEnum.Sold:
      return 'Vendido';
    case CarStatusEnum.SentToSale:
      return 'Enviado para Venda';
    case CarStatusEnum.Servicing:
      return 'Manutenção';
    case CarStatusEnum.TotalLost:
      return 'Perda Total';
    case CarStatusEnum.Lending:
      return 'Comodato';
    case CarStatusEnum.Returned:
      return 'Devolvido';
    case CarStatusEnum.LicensePlateProcess:
      return 'Veículos em processo de emplacamento';
    case CarStatusEnum.Separated:
      return 'Separado';
    default:
      return '-';
  }
};
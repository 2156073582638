export enum CarStatusEnum {
  Available = 1,
  Reserved = 2,
  Release = 3,
  Delivered = 4,
  Sold = 5,
  SentToSale = 6,
  Servicing = 7,
  TotalLost = 8,
  Lending = 9,
  Returned = 10,
  LicensePlateProcess = 11,
  Separated = 12
}

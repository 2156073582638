export enum KeyForKeyStatusEnum {
  Flow = 1,
  Start = 2,
  AwaitingPreparation = 3,
  Preparation = 4,
  UpdatePreparation = 5,
  AwaitingAdditiveSent = 6,
  CreatedAdditive = 7,
  AwaitingSignature = 8,
  Signature = 9,
  AwaitingAppointment = 10,
  Appointment = 11,
  Reappointment = 12,
  AwaitingKeyForKey = 13,
  FinishChange = 14,
  Canceled = 15
}

export function getDescription(status: KeyForKeyStatusEnum): string {
  switch (status) {
    case KeyForKeyStatusEnum.Start:
      return 'Iniciado';
    case KeyForKeyStatusEnum.AwaitingPreparation:
      return 'Aguardando Preparação';
    case KeyForKeyStatusEnum.AwaitingAdditiveSent:
      return 'Aguardando geração de aditivo';
    case KeyForKeyStatusEnum.AwaitingSignature:
      return 'Aguardando assinatura';
    case KeyForKeyStatusEnum.AwaitingAppointment:
      return 'Aguardando agendamento';
    case KeyForKeyStatusEnum.AwaitingKeyForKey:
      return 'Aguardando troca de chaves';
    case KeyForKeyStatusEnum.FinishChange:
      return 'Concluido';
    case KeyForKeyStatusEnum.Canceled:
      return 'Cancelado';
    default:
      return "";
  }
}
import { TrafficTicketTrackingStatusEnum } from '@/core/enum/traffic-ticket-tracking-status.enum'

export const trafficTicketTrackingStatusPipe = (status: TrafficTicketTrackingStatusEnum): string => {
  switch (status) {
    case TrafficTicketTrackingStatusEnum.NotNotified:
      return 'Não Notificado';
    case TrafficTicketTrackingStatusEnum.Notified:
      return 'Notificado';
    case TrafficTicketTrackingStatusEnum.NotifyFail:
      return 'Falha na Notificação';
    case TrafficTicketTrackingStatusEnum.DriverAppointed:
      return 'Condutor Indicado';
    case TrafficTicketTrackingStatusEnum.Contested:
      return 'Multa Contestada';
    case TrafficTicketTrackingStatusEnum.SendLink:
      return 'Link Enviado';
    case TrafficTicketTrackingStatusEnum.MarkAsPaid:
      return 'Marcado como Paga';
    case TrafficTicketTrackingStatusEnum.ChooseNotIndicate:
      return 'Optou em não indicar';
    default:
      return '-';
  }
};
import { ClaimStatusEnum } from '@/core/enum/claim-status.enum';

export const claimStatusPipe = (status: number) => {
  switch (status) {
    case ClaimStatusEnum.BudgetPending:
      return 'Pendente orçamento';
    case ClaimStatusEnum.DocumentationPending:
      return 'Pendente documentação';
    case ClaimStatusEnum.AuthorizedPending:
      return 'Pendente autorização';
    case ClaimStatusEnum.UnderRepair:
      return 'Em reparo';
    case ClaimStatusEnum.ThirdParties:
        return 'Seguradora terceiro';
    case ClaimStatusEnum.Closed:
      return 'Encerrado';
    case ClaimStatusEnum.Cancelled:
      return 'Cancelado';
  }
}
import { PaymentStatusEnum } from "../enum/payment-status.enum";


export const paymentStatusPipe = (status: PaymentStatusEnum): string => {
  switch (status) {
    case PaymentStatusEnum.Paid:
      return "Pago";
    case PaymentStatusEnum.Reversed:
      return "Estornado";
    case PaymentStatusEnum.RecurrencyStopped:
      return "Recorrência Interrompida";
    case PaymentStatusEnum.PaymentFail:
      return "Falha no Pagamento";
    case PaymentStatusEnum.NotPaid:
      return "Não Pago";
    case PaymentStatusEnum.Notified:
      return "Não Pago";
    case PaymentStatusEnum.RecurrencyActive:
      return "Recorrência Ativa";
    case PaymentStatusEnum.RecurrencyFinished:
      return "Recorrência Finalizada";
    case PaymentStatusEnum.SeparatePayment:
      return "Pago Avulso";
    case PaymentStatusEnum.MarkedAsPaid:
      return "Marcado Pago";
    case PaymentStatusEnum.Canceled:
      return "Cancelado";
    case PaymentStatusEnum.Authorized:
      return "Gerado";
    case PaymentStatusEnum.NotFinished:
      return "Não Finalizado";
    case PaymentStatusEnum.Exempt:
      return "Isentado";
    case PaymentStatusEnum.NotNotified:
      return "Não Notificado";
    case PaymentStatusEnum.PayBack:
      return "Devolvido";
    case PaymentStatusEnum.Scheduled:
      return "Agendado";
    case PaymentStatusEnum.ToOutdo: //apenas para o frontend
      return "A vencer";
    default:
      return "Unknown";
  }
};

import { CarRequestStatusEnum } from "../enum/car-request-status.enum";

export const carRequestStatusPipe = (status: number) => {
  switch (status) {
    case CarRequestStatusEnum.PendingDocumentation:
      return 'Documentação pendente';
    case CarRequestStatusEnum.Allowed:
      return 'Aprovado';
    case CarRequestStatusEnum.Denied:
      return 'Reprovado';
    case CarRequestStatusEnum.RequireChanges:
      return 'Requer Mudanças';
    case CarRequestStatusEnum.Pending:
      return "Pendente";
    case CarRequestStatusEnum.LinkSent:
      return "Link Enviado";
    case CarRequestStatusEnum.CarDelivered:
      return "Carro Entregue";
    case CarRequestStatusEnum.Canceled:
      return "Cancelado";
    case CarRequestStatusEnum.Paid:
      return "Pago";
    case CarRequestStatusEnum.FinancialApproved:
      return "Aprovado pelo Financeiro";
    case CarRequestStatusEnum.FinancialReproved:
      return "Reprovado pelo Financeiro";
    case CarRequestStatusEnum.DeliveryDateDefined:
      return "Entrega Agendada";
    case CarRequestStatusEnum.LinkExpired:
      return "Link de Pagamento Expirado";
    case CarRequestStatusEnum.PaymentFail:
      return "Falha no pagamento";
    case CarRequestStatusEnum.TransportAuthorizationSent:
      return "Autorização de Transporte enviada";
    case CarRequestStatusEnum.InProgress:
      return "Em análise";
    case CarRequestStatusEnum.Extended:
      return "Estendido";
    case CarRequestStatusEnum.ChassisChangeRequested:
      return "Solicitada troca de chassi";
    case CarRequestStatusEnum.ChassisChangePreparation:
      return "Aguardando preparação para troca de chassis";
    case CarRequestStatusEnum.ChassisChangeContratualAmendment:
      return "Aguardando emissão de aditivo contratual";
    case CarRequestStatusEnum.ChassisChangeDevolution:
      return "Aguardando devolução";
    case CarRequestStatusEnum.ApproveWithoutPayment:
      return "Aprovado sem Pagamento";
  }
}



const BYPASS_VALIDATION = () => true;

const EMAIL_REGEX = /^[a-z0-9.]+@[a-z0-9]+(\.[a-z]+)+((\.[a-z]+)+)?$/i;

export default function mixin () {
  return {
    data () {
      return {
        required: (value) => (value !== null && value !== undefined && value !== '') || 'Campo obrigatorio',
        minLength (length) {
          return (value) => !value || getLengthValue(value) >= length || `Mínimo  ${length} Caracteres`;
        },
        maxLength (length) {
          return (value) => !value || getLengthValue(value) <= length || `Máximo ${length} Caracteres`;
        },
        minValue (min) {
          if (min instanceof Date) {
            return (value) => !value || value.getTime() >= min.getTime() || `Mínimo ${min} Valor`;
          }
          if (typeof min === 'number' || typeof min === 'string' && /^\d+$/.test(min)) {
            min = +min;
            return (value) => !value || value >= min || `Mínimo ${min} Valor`;
          }
          if (typeof min === 'string') {
            return this.minLength(min.length);
          }
          
          return BYPASS_VALIDATION;
        },
        maxValue (max) {
          if (max instanceof Date) {
            return (value) => !value || value.getTime() <= max.getTime() || `Máximo ${max} Valor`;
          }
          if (typeof max === 'number' || typeof max === 'string' && /^\d+$/.test(max)) {
            max = +max;
            return (value) => !value || value <= max || `Máximo ${max} valor`;
          }
          if (typeof max === 'string') {
            return this.maxLength(max.length);
          }
          return BYPASS_VALIDATION;
        },
        isEmail: (value) => EMAIL_REGEX.test(value) || 'email invalido'
      };
    }
  };
}

function getLengthValue (value) {
  let val = 0;
  if (value && typeof value === 'string') {
    val = value.length;
  } else if (value) {
    val = value.toString().length;
  }
  return val;
}

const components = {
	install(vue: any, parameters: any) {
		const { store, putComponent } = parameters;
		putComponent('alert', () => import('@/core/components/alert/alert-component.vue'));
		putComponent('alert', 'item', () => import('@/core/components/alert/alert-item.vue'));
		putComponent('alert', 'download-file', () => import('@/core/components/alert/components/alert-download-file.vue'));
		putComponent('alert', 'single', () => import('@/core/components/alert/components/alert-single.vue'));
	}
};

export default components;
import { defineAsyncComponent } from 'vue'

const DEFAULT_COMPONENTS_PREFIX = 'Gosystem'

const component = {
  install(vue, parameters) {
    const _components = (parameters.gosystem.components = {})

    function convertNameComponent(prefixName: string, componentName: string) {
      if (!prefixName) prefixName = DEFAULT_COMPONENTS_PREFIX
      if (componentName.includes('-'))
        componentName = componentName
          .split('-')
          .map((comp: string) => `${comp.charAt(0).toUpperCase()}${comp.slice(1)}`)
          .join('')
      
      if (!componentName.startsWith(`${prefixName}`)) {
        componentName = prefixName + `${componentName.charAt(0).toUpperCase()}${componentName.slice(1)}`
      }
      return componentName
    }

    parameters.putComponent = function (prefixName, componentName, factory) {
      if (!(typeof prefixName === 'string')) return
      if (typeof componentName === 'function') {
        factory = componentName
        componentName = prefixName
        prefixName = null
      }
      componentName = convertNameComponent(prefixName, componentName)
      if (typeof factory !== 'function') {
        console.warn(`factory for component ${componentName} must be a function`)
        return
      }
      if (_components[componentName] && process.env.NODE_ENV !== 'production') {
        console.warn(`Component ${componentName} was overridden`)
      }
      _components[componentName] = factory
    }

    parameters.getComponent = function (prefixName, componentName) {
      if (!componentName) {
        componentName = convertNameComponent(prefixName, componentName)
      }
      const name = convertNameComponent(prefixName, componentName)
      return _components[name];
    }

    parameters.getComponentPrefix = function () {
      return DEFAULT_COMPONENTS_PREFIX
    }

    parameters.beforeInit.push(() => {
      Object.keys(_components).forEach((key) => {
        vue.component(key, defineAsyncComponent(_components[key]))
      })
    })
  }
}

export default component



const gridFilters = {
	install(vue, parameters) {
		const { putComponent } = parameters;
    putComponent('grid', 'header', () => import('@/core/components/grid/components/bones/grid-header.vue'));
    putComponent('grid', 'body', () => import('@/core/components/grid/components/bones/grid-body.vue'));
	}
};

export default gridFilters;
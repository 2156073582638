import component from './component';
import components from './components';
import menu from './modules/menu';
import client from './client'
import router from './router/router';
import store from './stores/store';
import mixin from './mixins/index';
import directive from '@/core/directive/index'
const core = {
	install(vue, parameters) {
    vue.config.globalProperties.$filters = [];
    vue.use(component, parameters);
    vue.use(router, parameters);
    vue.use(store, parameters);
    vue.use(mixin, parameters);
    parameters.hasNotification = true;
    
    parameters.store = store.pinia;
    parameters.router = router;
    parameters.routesByComponentName = parameters.routesByComponentName || [];
    const modules = [directive, menu, components, client]
    delete parameters.modules
    for (const module of modules) {
      vue.use(module, parameters)
    }
	}
};

export default core;

export enum SingleChargeTypeEnum {
  BreakdownRepair = 1,
  Parking = 2,
  MissingItem = 3,
  ReserveCarDebit = 4,
  ClaimDeductible = 5,
  Towed = 6,
  RecoveryCost = 7,
  Another = 8,
  DailySurplus = 9
};

export function getDescription(type: SingleChargeTypeEnum): string {
  switch (type) {
    case SingleChargeTypeEnum.BreakdownRepair:
      return "Reparo de Avarias";
    case SingleChargeTypeEnum.Parking:
      return "Parquímetro";
    case SingleChargeTypeEnum.MissingItem:
      return "Ausência de item";
    case SingleChargeTypeEnum.ReserveCarDebit:
      return "Débito de Carro Reserva";
    case SingleChargeTypeEnum.ClaimDeductible:
      return "Franquia de sinistro";
    case SingleChargeTypeEnum.Towed:
      return "Guincho";
    case SingleChargeTypeEnum.RecoveryCost:
      return "Custo de recuperação";
    case SingleChargeTypeEnum.Another:
      return "Outros";
    case SingleChargeTypeEnum.DailySurplus:
      return "Diária Excedente";
    default:
      return "Unknown";
  }
};
import { LotSourceEnum } from "../enum/lot-source-type.enum";

export const lotSourceEnumPipe = (status: LotSourceEnum): string => {
  switch (status) {
    case LotSourceEnum.Subscription:
      return 'Assinatura';
    case LotSourceEnum.ExceededKm:
      return 'Km Excedido';
    case LotSourceEnum.Ticket:
      return 'Infrações';
    case LotSourceEnum.TerminationFine:
      return 'Multa Rescisória';
    case LotSourceEnum.ExtendedContract:
      return 'Extensão de Contrato';
    case LotSourceEnum.BreakdownRepairs:
      return 'Reparo de avarias';
    case LotSourceEnum.SingleCharge:
      return 'Cobranças avulsas';
    default:
      return "";
  }
}
import Keycloak from 'keycloak-js'
import keycloakConfig from './keycloak.config'
import { authStore } from './auth-store'
import { sortBy } from 'lodash'

const keycloak = new Keycloak({
  url: keycloakConfig.url,
  realm: keycloakConfig.realm,
  clientId: keycloakConfig.clientId
})

export default {
  async init() {
    await keycloak.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      pkceMethod: 'S256',
      responseMode: 'query'
    })
  },

  async login() {
    await keycloak.login()
  },

  async logout() {
    await keycloak.logout()
  },

  async loadUserProfile() {
    await keycloak.loadUserProfile()
  },

  hasRole(role) {
    return (
      keycloak.authenticated &&
      keycloak.realmAccess.roles.includes(role)
    )
  },

  getUser() {
    return keycloak.authenticated ? keycloak.tokenParsed : null
  },

  isAuthenticated() {
    const authenticated = keycloak.authenticated;
    const stores = authStore();
    if (authenticated) {
      const tokenParsed = this.getUser();
      const scopes = tokenParsed?.scope.split(' ');
      const token = keycloak.token || "";
      localStorage.setItem('access_token', token);
      let roles = keycloak.realmAccess?.roles;
      roles = roles?.filter(r => r != 'uma_authorization' && r != 'default-roles-godrive' && r != 'offline_access');
      roles = sortBy(roles);
      const letters = `${tokenParsed?.name.charAt(0).toUpperCase()}${tokenParsed?.family_name?.split(" ")[tokenParsed?.family_name?.split(" ")?.length-1].charAt(0).toUpperCase()}`;
      const data = {
        authenticated: authenticated,
        idToken: keycloak.idToken,
        token: token,
        refreshToken: keycloak.refreshToken,
        user: {
          letters: letters,
          name: tokenParsed?.name,
          lastName: tokenParsed?.family_name,
          email: tokenParsed?.email,
          login: tokenParsed?.preferred_username,
          roles: roles,
          scopes: scopes
        }
      };
      stores.setDataKeycloak(data);
    return authenticated;
    }
  }
}
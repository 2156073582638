function getCrumb (parent, crumb) {
  return parent.map(({ id, title }) => {
    return {
      id,
      title: id === crumb.id ? crumb.title : title,
      disabled: id !== crumb.id,
      href: id === crumb.id ? `#${crumb.route}` : '',
      to: id === crumb.id ? `${crumb.route}` : ''
    };
  });
}

function getParent (parents, current) {
  const { id, title } = current;
  if (!parents) return [ { id, title } ];
  if (current.id === 'list') return parents;
  return [ ...parents, { id, title } ];
}

function convertFirstChartUpperCase (chart: string) {
  return `${chart.charAt(0).toUpperCase()}${chart.slice(1)}`
}

function registerScreen (menu, parameters, parent) {
  const { getComponentPrefix } = parameters;
  const [ prefix ] = parent;
  menu.components.forEach(({ id, path: component, items: breads }) => {
    if (!breads) breads = [actions.list];
    breads.forEach(b => {
      let componentName = id ? `${menu.id}${convertFirstChartUpperCase(b.id)}` : menu.id;
      const prefixId = convertFirstChartUpperCase(prefix.id);
      const componentId = convertFirstChartUpperCase(componentName);
      componentName = `${getComponentPrefix()}${prefixId}${componentId}`;
      const bread = { ...b.action, ...b, title: b?.title } 
      let path = bread.route || `/${prefix.id}/${menu.id}/${bread.url}`;
      if (path.endsWith('/')) path = path.substring(0, path.length - 1);
      if (!menu.route && bread.id === 'list') menu.route = path;
      const breadParent = getParent(parent, bread);
      const breadcrumb = getCrumb(breadParent, menu);
      parameters.router.addRoute({
        name: componentName,
        path: path, 
        component, 
        meta: {
          authRequired: true,
          breadcrumb,
          hierarchy: menu.hierarchy,
          hideNavigation: menu.hideNavigation,
          requiresAuth: menu.requiresAuth
        } 
      });
    });
  });
}

export async function generateRoute (menu, parameters, parent = null) {
  const parentId = getParent(parent, menu);
  const params = { ...parameters };

  if (menu.components) {
    registerScreen(menu, params, parentId);
  } else {
    if (!menu.items) return;
    if (menu.items instanceof Function) {
      menu.items = await menu.items();
    }
    await Promise.all(menu.items.map((i) => {
      return generateRoute(i, params, parentId);
    }));
  }
}

export const actions = {
  list: { id: 'list', url: '' },
  new: { id: 'new', url: 'new', title: 'Novo' },
  edit: { id: 'edit', url: ':id', title: 'Edição', props: true }
};

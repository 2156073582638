import { ThirdPartiesFileExtension } from '@/core/enum/third-parties-file-extension.enum';

export const thirdPartiesFileTypePipe = (status: number) => {
  switch (status) {
    case ThirdPartiesFileExtension.Cnh:
      return 'CNH';
    case ThirdPartiesFileExtension.Crlv:
      return 'CRVL';
    case ThirdPartiesFileExtension.DamageImages:
      return 'Fotos danos';
  }
}
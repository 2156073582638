import { SingleChargeStatusEnum } from "../enum/single-charge-status.enum";

export const singleChargeStatusPipe = (type: SingleChargeStatusEnum): string => {
  switch (type) {
    case SingleChargeStatusEnum.Paid:
      return "Pago";
    case SingleChargeStatusEnum.NotPaid:
      return "Não Pago";
    case SingleChargeStatusEnum.Canceled:
      return "Cancelado";
    case SingleChargeStatusEnum.Reversed:
      return "Estornado";
    default:
      return "Unknown";
  }
}
const main = {
  install(vue: any, parameters: any) {
    const REGISTER_MENU = getRegisterMenu()
    parameters.registerMenu(REGISTER_MENU, parameters);
    parameters.putComponent('details-monthly-payments-data', () => import('@/modules/monthly-payments/monthly-payments-details/details-monthly-payments-data.vue'))
    parameters.putComponent('monthly-payments-buttons', () => import('@/modules/monthly-payments/monthly-payments-buttons.vue'))
    parameters.putComponent('details-monthly-payments-separate', () => import('@/modules/monthly-payments/monthly-payments-details/details-monthly-payments-separate.vue'))
  }
}

import { UserRoleEnum } from '@/core/enum/user-role.enum'

export default main

const getRegisterMenu = () => ({
  id: 'monthlyPayments',
  title: 'Mensalidades',
  icon: 'fa-solid fa-sack-dollar',
  order: 5,
  groupNameKey: 'monthlyPayments',
  route: '/monthlyPayments/list',
  roles: [UserRoleEnum.External, UserRoleEnum.Administrative, UserRoleEnum.Admin],
  items: [
    {
      id: 'list',
      icon: 'fa-solid fa-file-invoice-dollar',
      components: [
        {
          path: () => import('./monthly-payments.vue'),
        },
      ]
    }
  ],
})
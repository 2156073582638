import grid from './grid';
import notification from './alert';

const components = {
	install(vue, parameters) {
		const { store, putComponent } = parameters;

		putComponent('profile-user', () => import('./profile-user.vue'));
		putComponent('button', () => import('./component-button.vue'));
		putComponent('toolbar', () => import('./component-toolbar.vue'));
		putComponent('card-dashboard', () => import('./card-dashboard.vue'));
		putComponent('chip-status', () => import('./chip-status.vue'));
		putComponent('loading', () => import('./loading.vue'));
		putComponent('loading-image', () => import('./loading-image.vue'));
		putComponent('pdf-animation', () => import('./pdf-animation.vue'));
		putComponent('uploader', () => import('./uploader.vue'));
		putComponent('select-state', () => import('./select-state.vue'));
		putComponent('visualize-file', () => import('./visualize-file.vue'));
		putComponent('back-to-previous-page', () => import('./back-to-previous-page.vue'));
		putComponent('dialog', () => import('./component-dialog.vue'));
		putComponent('box-alert-message', () => import('./box-alert-message.vue'));
		vue.use(grid, parameters);
		if (parameters.hasNotification) {
			vue.use(notification, parameters);
		}
	}
};

export default components;
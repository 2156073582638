import { ClaimHistoryTagEnum } from '@/core/enum/claim-history-tag.enum';

export const claimHistoryTagPipe = (status: number) => {
  switch (status) {
    case ClaimHistoryTagEnum.Flow:
      return 'Fluxo';
    case ClaimHistoryTagEnum.System:
      return 'Automático';
    case ClaimHistoryTagEnum.Sac:
      return 'SAC';
    case ClaimHistoryTagEnum.Internal:
      return 'Interno';
  }
}
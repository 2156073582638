const main = {
  install(vue: any, parameters: any) {
    const REGISTER_MENU = getRegisterMenu()
    parameters.registerMenu(REGISTER_MENU, parameters);
    parameters.router.addRoute({
      path: '/',
      name: 'home',
      component: () => import('@/modules/main/main-list.vue'),
      meta: {
        authRequired: true,
        breadcrumb: { title: 'Home' }
      },
    }) 
  }
}

import { UserRoleEnum } from '@/core/enum/user-role.enum'

export default main

const getRegisterMenu = () => ({
  id: 'home',
  title: 'Home',
  icon: 'fa-solid fa-house',
  order: 1,
  groupNameKey: 'home',
  route: '/',
  roles: [UserRoleEnum.External, UserRoleEnum.Admin],
})
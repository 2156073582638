import { PaymentModalityEnum } from "../enum/payment-modality.enum";

export const paymentModalityPipe = (type: PaymentModalityEnum): string => {
  switch (type) {
    case PaymentModalityEnum.CashBillet:
      return "Boleto à vista";
    case PaymentModalityEnum.TermBillet:
      return "Boleto à prazo";
    case PaymentModalityEnum.RecurrentCreditCard:
      return "Cartão de Crédito Recorrente";
    case PaymentModalityEnum.CreditCard:
      return "Cartão de Crédito";
    case PaymentModalityEnum.Barter:
      return "Permuta";
    case PaymentModalityEnum.PicPay:
      return "Picpay";
    case PaymentModalityEnum.Pix:
      return "Pix";
    case PaymentModalityEnum.ExchangeWithChange:
      return "Troca com Troco";
    case PaymentModalityEnum.ExchangeWithResidual:
      return "Troca com Residual";
    case PaymentModalityEnum.Other:
      return "Outros";
    default:
      return "Desconhecido";
  }
}
import { CarMovementEnum } from "../enum/car-movement.enum";

export const carMovementTypePipe = (status: number) => {
  switch (status) {
    case CarMovementEnum.Stock:
      return 'Estoque';
    case CarMovementEnum.RequestMovement:
      return 'Solicitada Movimentação';
    case CarMovementEnum.Movement:
      return 'Em Movimentação';
    case CarMovementEnum.PendingNF:
      return 'Nota Pendente';
  }
}
export enum CustomerRenovationAllowanceStatusEnum {
  Able = 1,
  FinancialConsult = 2,
  Unapt = 3,
}

export function getDescription(status: CustomerRenovationAllowanceStatusEnum): string {
  switch (status) {
    case CustomerRenovationAllowanceStatusEnum.Able:
      return "Apto";
    case CustomerRenovationAllowanceStatusEnum.FinancialConsult:
      return "Consultar financeiro";
    case CustomerRenovationAllowanceStatusEnum.Unapt:
      return "Não Apto";
    default:
      return "";
  }
}
import validation from './validation';

const mixins = {
	install(vue, parameters) {
    if (!parameters) {
      console.warn('could not install mixins.');
      return;
    }
   
    parameters.gosystem.rules = validation;
	}
};

export default mixins;
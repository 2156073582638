
import { claimStatusPipe } from '@/core/directive/claim-status.directive'
import { carStockOriginPipe } from '@/core/directive/car-stock-origin.directive'
import { thirdPartiesFileTypePipe } from '@/core/directive/thirth-parties-file-type.directive'
import { claimBudgetStatusPipe } from '@/core/directive/claim-budget-status.directive'
import { claimHistoryTypePipe } from '@/core/directive/claim-history-type.directive'
import { claimHistoryTagPipe } from '@/core/directive/claim-history-tag.directive'
import { keyForKeyStatusPipe } from '@/core/directive/key-for-key-status.directive'
import { carRequestCarHistoryStatusPipe } from "@/core/directive/car-request-car-history-status.directive";
import { contractStatusPipe } from "@/core/directive/contract-status.directive";
import { carStatusPipe } from "@/core/directive/car-status.directive";
import { carRequestStatusPipe } from "@/core/directive/car-request-status.directive";
import { carMovementTypePipe } from './car-movement.directive'
import { customerRenovationAllowanceStatusPipe } from './customer-renovation-allowance-status.enum.directive'
import { trafficTicketStatusPipe } from "./traffic-ticket-status.directive";
import { trafficTicketPayableStatusPipe } from "./traffic-ticket-payable-status.directive";
import { trafficTicketPaymentStatusPipe } from "./traffic-ticket-payment-status.directive";
import { trafficTicketTrackingStatusPipe } from "./traffic-ticket-tracking-status.directive";
import { lotSourceEnumPipe } from './lot-source-type.directive'
import { paymentStatusPipe } from './payment-status.directive'
import { paymentModalityPipe } from './payment-modality.directive'
import { singleChargeTypePipe } from './single-charge-type.directive'
import { singleChargeStatusPipe } from './single-charge-status.directive'

import formatCpfCnpj from '@/core/directive/CpfCnpjPipe'
import formatDate from '@/core/directive/FormatDatePipe'

const pipes = {
  install(vue) {
    const enumPipes = { claimStatusPipe, carStockOriginPipe, thirdPartiesFileTypePipe, formatCpfCnpj, lotSourceEnumPipe, singleChargeStatusPipe,
      claimBudgetStatusPipe, claimHistoryTypePipe, claimHistoryTagPipe, keyForKeyStatusPipe, formatDate, paymentStatusPipe, singleChargeTypePipe,
      carRequestCarHistoryStatusPipe, carRequestStatusPipe, contractStatusPipe, carStatusPipe, carMovementTypePipe, paymentModalityPipe,
      customerRenovationAllowanceStatusPipe, trafficTicketStatusPipe, trafficTicketPayableStatusPipe, trafficTicketTrackingStatusPipe, trafficTicketPaymentStatusPipe };
    vue.config.globalProperties.$filters = enumPipes   
  }
}

export default pipes;
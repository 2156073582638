import gridFields from './components/fields';
import gridFilters from './components/filters';
import gridBones from './components/bones';

const components = {
	install(vue, parameters) {
		const { store, putComponent } = parameters;
		putComponent('grid', () => import('@/core/components/grid/grid.vue'));
		putComponent('grid', 'configuration', () => import('@/core/components/grid/grid-configuration.vue'));
		putComponent('grid', 'export', () => import('@/core/components/grid/grid-export.vue'));
		vue.use(gridFields, parameters);
		vue.use(gridFilters, parameters);
		vue.use(gridBones, parameters);

	}
};

export default components;
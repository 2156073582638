export enum PaymentStatusEnum {
    Paid = 1,
    Reversed = 2,
    RecurrencyStopped = 3,
    PaymentFail = 4,
    NotPaid = 5,
    Notified = 6,
    RecurrencyActive = 8,
    RecurrencyFinished = 9,
    SeparatePayment = 10,
    MarkedAsPaid = 11,
    Canceled = 12,
    Authorized = 13,
    NotFinished = 14,
    Exempt = 15,
    NotNotified = 16,
    PayBack = 17,
    Scheduled = 18,
    ToOutdo = 49 //apenas para o frontend
  };
  
  export function getDescription(status: PaymentStatusEnum): string {
    switch (status) {
      case PaymentStatusEnum.Paid:
        return "Pago";
      case PaymentStatusEnum.Reversed:
        return "Estornado";
      case PaymentStatusEnum.RecurrencyStopped:
        return "Recorrência Interrompida";
      case PaymentStatusEnum.PaymentFail:
        return "Falha no Pagamento";
      case PaymentStatusEnum.NotPaid:
        return "Não Pago";
      case PaymentStatusEnum.Notified:
        return "Não Pago";
      case PaymentStatusEnum.RecurrencyActive:
        return "Recorrência Ativa";
      case PaymentStatusEnum.RecurrencyFinished:
        return "Recorrência Finalizada";
      case PaymentStatusEnum.SeparatePayment:
        return "Pago Avulso";
      case PaymentStatusEnum.MarkedAsPaid:
        return "Pago";
      case PaymentStatusEnum.Canceled:
        return "Cancelado";
      case PaymentStatusEnum.Authorized:
        return "Autorizado";
      case PaymentStatusEnum.NotFinished:
        return "Não Finalizado";
      case PaymentStatusEnum.Exempt:
        return "Isentado";
      case PaymentStatusEnum.NotNotified:
        return "Não Notificado";
      case PaymentStatusEnum.PayBack:
        return "Devolvido";
      case PaymentStatusEnum.Scheduled:
        return "Agendado";
      default:
        return "Unknown";
    }
  };

import { SingleChargeTypeEnum } from "../enum/single-charge-type.enum";

export const singleChargeTypePipe = (type: SingleChargeTypeEnum): string => {
  switch (type) {
    case SingleChargeTypeEnum.BreakdownRepair:
      return "Reparo de Avarias";
    case SingleChargeTypeEnum.Parking:
      return "Parquímetro";
    case SingleChargeTypeEnum.MissingItem:
      return "Ausência de item";
    case SingleChargeTypeEnum.ReserveCarDebit:
      return "Débito de Carro Reserva";
    case SingleChargeTypeEnum.ClaimDeductible:
      return "Franquia de sinistro";
    case SingleChargeTypeEnum.Towed:
      return "Guincho";
    case SingleChargeTypeEnum.RecoveryCost:
      return "Custo de recuperação";
    case SingleChargeTypeEnum.Another:
      return "Outros";
    case SingleChargeTypeEnum.DailySurplus:
      return "Diária Excedente";
    default:
      return "Unknown";
  }
}
import { KeyForKeyStatusEnum } from '@/core/enum/key-for-key-status.enum';

export const keyForKeyStatusPipe = (status: number) => {
  switch (status) {
    case KeyForKeyStatusEnum.Flow:
      return "Fluxo";
    case KeyForKeyStatusEnum.Start:
      return "Iniciada";
    case KeyForKeyStatusEnum.AwaitingPreparation:
      return "Aguardando preparação";
    case KeyForKeyStatusEnum.Preparation:
      return "Preparado";
    case KeyForKeyStatusEnum.UpdatePreparation:
      return "Preparação atualizada";
    case KeyForKeyStatusEnum.AwaitingAdditiveSent:
      return "Aguardando envio de aditivo";
    case KeyForKeyStatusEnum.CreatedAdditive:
      return "Aditivo criado";
    case KeyForKeyStatusEnum.AwaitingSignature:
      return "Aguardando assinatura";
    case KeyForKeyStatusEnum.Signature:
      return "Assinado";
    case KeyForKeyStatusEnum.AwaitingAppointment:
      return "Aguardando agendamento";
    case KeyForKeyStatusEnum.Appointment:
      return "Agendado";
    case KeyForKeyStatusEnum.Reappointment:
      return "Reagendado";
    case KeyForKeyStatusEnum.AwaitingKeyForKey:
      return "Aguardando troca de chaves";
    case KeyForKeyStatusEnum.FinishChange:
      return "Finalizada troca";
    case KeyForKeyStatusEnum.Canceled:
      return "Cancelada";
  }
}
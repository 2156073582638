import { CarRequestCarHistoryStatusEnum } from '@/core/enum/car-request-car-history-status.enum';

export const carRequestCarHistoryStatusPipe = (status: CarRequestCarHistoryStatusEnum): string => {
  switch (status) {
    case CarRequestCarHistoryStatusEnum.Active:
      return 'Veículo com o cliente"';
    case CarRequestCarHistoryStatusEnum.RenovationWithSameCar:
      return 'Em processo de renovação com o mesmo veículo';
    case CarRequestCarHistoryStatusEnum.ChassisChange:
      return 'Veiculo escolhido para realizar troca de chassis';
    case CarRequestCarHistoryStatusEnum.Unlinked:
      return 'Veículo desvinculado';
  }
}
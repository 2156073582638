import { defineStore } from 'pinia'
import { orderBy } from 'lodash';
import type { IGroup } from "@/core/interface/IGroup";
import type { IMenu } from '@/core/interface/IMenu';


function setMenuHierarchy (menu, parent = null) {
  if (!menu.id) { return; }
  const hierarchy = parent ? parent + '-' + menu.id.toLowerCase() : menu.id.toLowerCase();
  menu.hierarchy = hierarchy;
  if (menu.items) menu.items.forEach((m: any) => setMenuHierarchy(m, hierarchy));
};

export const STORE_NAME = 'menu';
export const menuStore = defineStore(STORE_NAME, {
  state: () => ({
    groups: {} as IGroup,
    menus: [] as IMenu[],
    breadcrumbs: [],
    history: [],
    loading: false,
    isLegacy: false,
    link: '',
  }),
  actions: {
    setLink (link: string) : string { 
      this.link = link;
      return link;
    },
    orderedGroups () { 
      const groupedData = this.menus.reduce((acc, item) => {
        const group = item?.groupNameKey;
        if (!!item.items) {
          if (!acc[group]) {
            acc[group] = {
              ...item,
              groupNameKey: group, 
              items: [] 
            };
          }
          acc[group].items.push(item.items);
          acc[group].items = orderBy([].concat(...acc[group].items),['order'],['asc']);
        } else {
          acc[item.id] = {...item };
        }
        return acc;
      }, {});
      
      let itemsMenu = [];
      for (let group in groupedData) {
        itemsMenu.push(groupedData[group]);
        this.menus = orderBy(itemsMenu, ['order'], ['asc']);
      }
    },
    register(menu) {
      if (!menu && !menu.id && !menu.name) return;
      setMenuHierarchy(menu);
      this.menus.push(menu);
      this.orderedGroups();
    },
    addBreadcrumb (meta) {
      this.breadcrumbs = [];
      const bread = meta.breadcrumb;
      this.isLegacy = bread && bread.length && typeof bread[0] == 'string' ? bread[0].includes('Legacy') : false;
      if (typeof bread == 'object') {
        bread.map(b =>  this.breadcrumbs.push(b));
      }
    },
  }
})



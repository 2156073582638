

const gridFields = {
	install(vue, parameters) {
		const { putComponent, getComponent } = parameters;
    const chip = getComponent('', 'chip-status')
    putComponent('grid', 'date', () => import('@/core/components/grid/components/fields/grid-date.vue'));
    putComponent('grid', 'date-time', () => import('@/core/components/grid/components/fields/grid-date-time.vue'));
    putComponent('grid', 'money', () => import('@/core/components/grid/components/fields/grid-money.vue'));
    putComponent('grid', 'phone', () => import('@/core/components/grid/components/fields/grid-phone.vue'));
    putComponent('grid', 'license-plate', () => import('@/core/components/grid/components/fields/grid-license-plate.vue'));
    putComponent('grid', 'boolean', () => import('@/core/components/grid/components/fields/grid-boolean.vue'));
    putComponent('grid', 'document', () => import('@/core/components/grid/components/fields/grid-document.vue'));
    putComponent('grid', 'text', () => import('@/core/components/grid/components/fields/grid-text.vue'));
    putComponent('grid', 'chip', chip);
	}
};

export default gridFields;
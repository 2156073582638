import { defineStore } from 'pinia'
import type { Auth } from '../core/models/auth-model';
import type { UserProfileAuth } from '../core/models/user-profile-model';

export const STORE_NAME = 'auth';
export const authStore = defineStore(STORE_NAME, {
  state: () => ({
    auth: {} as Auth,
  }),
  actions: {
    setDataKeycloak(data: any) {
      this.auth = data;
    },
    setId(id: string) {
      this.auth.user.id = id;
    }
  },
  getters: {
    getAuth(state): Auth {
      return state.auth;
    },
    getProfiles(state): UserProfileAuth["roles"] {
      return state.auth?.user?.roles;
    },
    getUserLogged(state): UserProfileAuth {
      return state.auth?.user;
    }
  }
});
export enum UserRoleEnum {
  Admin = "Administrador",
  Administrative = "Administrativo",
  User = "Usuario",
  Seller = "Vendedor",
  Financial = "Financeiro",
  Manager = "Gestão",
  FleetManagement = "Gestão de Frotas",
  TicketManagement = "Gestão de Multas",
  CarReviewManagement = "Gestão de Revisão",
  Contract = "Contratos",
  SAC = "SAC",
  Master = "Master",
  External = "Externo",
  CreditDesk = "Mesa de Crédito",
  Demobilization = "Desmobilização",
  Insurance = "Seguros",
  KmControl = "Controle de Kms",
  Inventory = "Estoque",
  Commercial = "Comercial",
  RPA = "RPA",
  ExternalSwint = "Externo Swint",
  Crc = "Crc",
  Jobs = "Jobs",
  Sistema = "Sistema",
  Dukar = "Dukar"
}

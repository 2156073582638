import { ContractStatusEnum } from '@/core/enum/contract-status.enum';

export const contractStatusPipe = (status: ContractStatusEnum): string => {
  switch (status) {
    case ContractStatusEnum.InProgess:
      return 'Em Análise';
    case ContractStatusEnum.DocumentationSent:
      return 'Documentação Enviada';
    case ContractStatusEnum.Current:
      return 'Vigente';
    case ContractStatusEnum.TerminatedAgreement:
      return 'Contrato Rescindido';
    case ContractStatusEnum.Finished:
      return 'Finalizado';
    case ContractStatusEnum.Renewed:
      return 'Renovado';
    case ContractStatusEnum.Canceled:
      return 'Cancelado';
    default:
      return '-';
  }
};
<template>
  <q-layout view="hHh LpR fFf">
    <q-header elevated class="bg-purple text-white">
      <q-toolbar>
        <q-btn dense flat round icon="fa-solid fa-bars" @click="toggleLeftDrawer" />
        <q-toolbar-title>
          <q-img style="width: 130px" src="./assets/images/godrive_fundo_escuro.svg" />
        </q-toolbar-title>
        <gosystem-alert v-if="isAuthenticatedApiKeyclock" :userLogged="userLogged"/>
        <gosystem-profile-user v-if="isAuthenticatedApiKeyclock"/>
      </q-toolbar>
    </q-header>
    <q-drawer v-if="isAuthenticatedApiKeyclock" show-if-above v-model="leftDrawerOpen" :width="250" side="left" bordered>
      <gosystem-menu-component />
    </q-drawer>
    <q-page-container>
      <gosystem-breadcrumbs v-if="isAuthenticatedApiKeyclock" />
      <div class="q-px-md q-pb-lg" v-if="!isAuthentication">
        <router-view :key="$route.fullPath"/>
      </div>
      <div class="q-mt-lg" v-else><gosystem-loading :animationData="animationData"/></div>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import keycloakService from '@/auth/key-cloak-service'
import { authStore } from '@/auth/auth-store';
import { http } from '@/core/client';
import animationData from '@/assets/loading-system.json';

const storeAuth = authStore();
const leftDrawerOpen = ref(false);
const userLogged = ref();
const isAuthenticatedApiKeyclock = ref(false);
const isAuthentication = ref(true);

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value;
}

const router = useRouter();
const route = useRoute();

onMounted(() => {
  checkAuthentication();
  router.replace(router.currentRoute.value.fullPath);
});

const checkAuthentication = async () => {
  if (keycloakService.isAuthenticated()) {
    userLogged.value = await http.get("account/loginSso");
    if (userLogged.value.status == '401') {
        isAuthenticatedApiKeyclock.value = false;
        router.push({ path: '/unauthorized' })
    } else 
    {
      if (route.name == 'Unauthorized') {
        router.push({ path: '/' })
      }
      isAuthenticatedApiKeyclock.value = true;
      storeAuth.setId(userLogged.value.id)
    }
    isAuthentication.value = false;
    
  } else {
    keycloakService.login();
  }
};

</script>

<style scoped></style>


const gridFilters = {
	install(vue, parameters) {
		const { putComponent } = parameters;
    putComponent('grid', 'filter-string', () => import('@/core/components/grid/components/filters/grid-filter-string.vue'));
    putComponent('grid', 'filter-date', () => import('@/core/components/grid/components/filters/grid-filter-date.vue'));
    putComponent('grid', 'filter-enum', () => import('@/core/components/grid/components/filters/grid-filter-enum.vue'));
    putComponent('grid', 'filters', () => import('@/core/components/grid/components/filters/grid-filters.vue'));
	}
};

export default gridFilters;
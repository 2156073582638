import { CustomerRenovationAllowanceStatusEnum } from '@/core/enum/customer-renovation-allowance-status.enum';

export const customerRenovationAllowanceStatusPipe = (status: number) => {
  switch (status) {
    case CustomerRenovationAllowanceStatusEnum.Able:
      return "Apto";
    case CustomerRenovationAllowanceStatusEnum.FinancialConsult:
      return "Consultar financeiro";
    case CustomerRenovationAllowanceStatusEnum.Unapt:
      return "Não Apto";
    default:
      return "";
  }
}
import { TrafficTicketPaymentStatusEnum } from '@/core/enum/traffic-ticket-payment-status.enum';

export const trafficTicketPaymentStatusPipe = (status: TrafficTicketPaymentStatusEnum): string => {
  switch (status) {
    case TrafficTicketPaymentStatusEnum.NotPaid:
      return 'Não Pago';
    case TrafficTicketPaymentStatusEnum.Paid:
      return 'Pago';
    case TrafficTicketPaymentStatusEnum.PaymentFail:
      return 'Erro na cobrança';
    case TrafficTicketPaymentStatusEnum.Reversed:
      return 'Estornado';
    case TrafficTicketPaymentStatusEnum.Exempt:
      return 'Isentado';
    case TrafficTicketPaymentStatusEnum.MarkAsPaid:
      return 'Marcado como Paga';
    default:
      return '-';
  }
};
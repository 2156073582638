import { UserRoleEnum } from '@/core/enum/user-role.enum'

const charges = {
  install(vue: any, parameters: any) {
    const REGISTER_MENU = getRegisterMenu(parameters.routeActions)
    parameters.registerMenu(REGISTER_MENU, parameters)
    parameters.putComponent('details-charges', () => import('@/modules/charges/details-charges.vue'))
    parameters.putComponent('charges', 'button-payment', () => import('@/modules/charges/charges-payment-button.vue'))
    parameters.putComponent('details-subscription', () => import('@/modules/charges/details-subscription.vue'))
    parameters.putComponent('details-single-charge', () => import('@/modules/charges/details-single-charge.vue'))
    parameters.putComponent('details-traffic-ticket-charge', () => import('@/modules/charges/details-traffic-ticket-charge.vue'))
    parameters.putComponent('details-trip-extract-charge', () => import('@/modules/charges/details-trip-extract-charge.vue'))
    parameters.putComponent('receipt-payment', () => import('@/modules/charges/receipt-payment.vue'))
    parameters.putComponent('tabs-charges-main', () => import('@/modules/charges/tabs/tabs-charges-main.vue'))
    parameters.putComponent('charge-trip-extracts', () => import('@/modules/charges/tabs/charge-trip-extracts.vue'))
    parameters.putComponent('early-termination-ticket', () => import('@/modules/charges/tabs/early-termination-ticket.vue'))
    parameters.putComponent('single-charges', () => import('@/modules/charges/tabs/single-charges.vue'))
    parameters.putComponent('charge-no-data', () => import('@/modules/charges/tabs/charge-no-data.vue'))
    parameters.putComponent('charges-payment-button-checkout', () => import('@/modules/charges/tabs/charges-payment-button-checkout.vue'))
  }
}

const getRegisterMenu = (actions: { list: any}) => ({
  id: 'charges',
  title: 'Demais cobranças',
  groupNameKey: 'charges',
  icon: 'fa-solid fa-file-invoice-dollar',
  order: 4,
  route: '/charges/list',
  roles: [UserRoleEnum.External, UserRoleEnum.Administrative, UserRoleEnum.Admin],
  items: [
    {
      id: 'list',
      icon: 'fa-solid fa-file-invoice-dollar',
      components: [
        {
          path: () => import('./charges.vue'),
        },
      ]
    }
  ],
})


export default charges
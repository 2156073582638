import { createRouter, createWebHashHistory } from 'vue-router';
import { menuStore } from '@/core/modules/menu/menu-store'
 
const router = createRouter({
  history: createWebHashHistory(),
  strict: false,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/core/views/home-view.vue'),
      meta: {
        authRequired: true,
        breadcrumb: { title: 'Home' }
      },
    },
    {
      path: '/unauthorized',
      name: 'Unauthorized',
      meta: {
        authRequired: false,
        breadcrumb: { title: 'Unauthorized' }
      },
      component: () => import('@/core/views/unauthorized-view.vue')
    },
    {
      path: '/loading',
      name: 'Loading',
      meta: {
        authRequired: false,
      },
      component: () => import('@/core/views/loading-view.vue')
    },
    { path: '/:pathMatch(.*)*', name: 'Not Found', component: () => import('@/core/views/404-view.vue') },
  ]
});

router.beforeEach(async (to, from, next) => {
    const stores = menuStore();
    const hasLegacy = to.fullPath.includes('/legacy/');
    if (to.meta.authRequired || hasLegacy) {
      const basePath = window.location.toString();
      if (hasLegacy) {
        next()
        console.log('go to legacy system');
      } else  {
        next()
      }
      stores.addBreadcrumb(to.meta);
      stores.setLink(to.path)
      //
      // else {
      //   next({ name: 'Unauthorized' })
      // }
    } 
    else 
      next()
});

export default router

import axios, { type AxiosRequestConfig, type ResponseType } from 'axios';
import { Notify } from 'quasar'

export const httpAxios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json',
    Authorization : `Bearer ${localStorage.getItem("access_token")}`
  }
});

const headers = { ...httpAxios.defaults.headers };


const _execute = async (method: string, url: string, data: any = null, returnFullResponse = false, responseType: ResponseType = 'json') => {
  const config: AxiosRequestConfig = {
    method,
    url,
    data,
    responseType
  };
  
  return httpAxios(config).then(response => {
     return returnFullResponse ? response : response.data;
  }).catch(req => {
    const codeError  = req?.response?.status;
    const data = req.response.data
    if (codeError == 400) {
      Notify.create({
        progress: true,
        message: `${data?.Message}`,
        color: 'orange',
        textColor: 'white',
        position: 'top-right',
        icon: 'fa-solid fa-circle-info',
        html: true
      });
    }
    else {
      Notify.create({
        progress: true,
        message: `${req.message} <br> ${data?.Message}`,
        color: 'red',
        textColor: 'white',
        position: 'top-right',
        icon: 'warning',
        html: true
      });
    }
    return null;
  })
  .finally(() => {
    httpAxios.defaults.headers = { ...headers };
    httpAxios.defaults.headers['Content-Type'] = 'application/json';
    httpAxios.defaults.responseType = 'json';
  });
}

export const http = {
  get<T> (endPoint: string,  id?: string | number) : Promise<T> {
    if (id)
      return _execute('get', `/${endPoint}/${id}`)
    else 
      return _execute('get', `${endPoint}`)
  },

  list<T> (endPoint: string, data?: {}, id?: string | number) : Promise<T> {
    if (id) return _execute('post', `/${endPoint}/${id}`, data) 
    else return _execute('post', `/${endPoint}`, data)
  },

  post<T> (endPoint: string, data: {}) : Promise<T> {
    return _execute('post', `/${endPoint}`, data)
  },

  put<T> (endPoint: string, data?: {}) : Promise<T> {
    return _execute('put', `/${endPoint}`, data)
  },

  patch<T> (endPoint: string, data: {}, id: string | number) : Promise<T> {
    return _execute('patch', `/${endPoint}/${id}`, data) 
  },

  delete<T> (endPoint: string, id: string | number) : Promise<T> {
    return _execute('delete', `/${endPoint}/${id}`)
  },

  upload (file: File) {
    const fileUpload = Array.isArray(file) ? file[0] : file;
    const formData = new FormData();
    formData.append('file', fileUpload);
    httpAxios.defaults.headers['Content-Type'] = 'multipart/form-data';
    return _execute('post', `media/uploadFile`, formData)
  },

  download(id:string) {
    return _execute('get', `media/download/${id}`);
  },
  
  downloadFile(endPoint: string) {
    return _execute('get', `${endPoint}`, {}, false, 'blob');
  },

  downloadByAlert(id:string) {
    return _execute('get', `media/download/${id}/new`);
  },

  async archive(endPoint: string, data: {}, isDownload: boolean = true): Promise<{ file: any, byteArray: string, blob: Blob }> {
    const response = await _execute('post', `/${endPoint}`, data, true);
    let blob = new Blob();
    const { file } = response.data;
    const byteCharacters = atob(file.fileContents);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], { type: file.contentType });
    if (isDownload) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.fileDownloadName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
    return { file, byteArray: byteCharacters, blob};
  },

}


const client = {
	http: http,
  httpAxios: httpAxios,
  install(vue: any, parameters: any) {
    parameters.http = http;
    parameters.httpAxios = httpAxios;
	}
};

export default client;
import { ClaimHistoryTypeEnum } from '../enum/claim-history-type.enum';

export const claimHistoryTypePipe = (status: number) => {
  switch (status) {
    case ClaimHistoryTypeEnum.OpeningProtocol:
      return 'Abertura de protocolo';
    case ClaimHistoryTypeEnum.RegistrationInformation:
      return 'Registro de informações';
    case ClaimHistoryTypeEnum.SubmissionDocumentation:
      return 'Enviado documentação';
    case ClaimHistoryTypeEnum.OpeningBudget:
      return 'Abertura de orçamento';
    case ClaimHistoryTypeEnum.BudgetAttachment:
      return 'Anexo de orçamentos';
    case ClaimHistoryTypeEnum.BookingCarRequest:
      return 'Solicitação de carro reserva';
      case ClaimHistoryTypeEnum.BookingCarEdit:
      return 'Edição de carro reserva';
    case ClaimHistoryTypeEnum.ChangeStatus:
      return 'Alteração de status';
    case ClaimHistoryTypeEnum.ChangeStatusBudget:
      return 'Alteração status de orçamento';
    case ClaimHistoryTypeEnum.ChangeStatusBookingCar:
      return 'Alteração status de carro reserva';    
    case ClaimHistoryTypeEnum.Deletion:
      return 'Ação de deleção';
    case ClaimHistoryTypeEnum.AutomaticSendingEmail:
      return 'Envio automático de email';
    case ClaimHistoryTypeEnum.AutomaticSendingSms:
      return 'Envio automático de SMS';
    case ClaimHistoryTypeEnum.Internal:
      return 'Observação interna';
    case ClaimHistoryTypeEnum.CustomerContact:
      return 'Contato com Cliente';
    case ClaimHistoryTypeEnum.OfficeContact:
      return 'Contato com a Oficina';
    case ClaimHistoryTypeEnum.CancelClaim:
      return 'Cancelada';
    case ClaimHistoryTypeEnum.Finish:
      return 'Finalizado';
    case ClaimHistoryTypeEnum.Responsible:
      return 'Responsável';
  }
}

const trafficTicket = {
  install(vue: any, parameters: any) {
    const REGISTER_MENU = getTrafficTicketRegisterMenu(parameters.routeActions)
    parameters.registerMenu(REGISTER_MENU, parameters)
  }
}

import { UserRoleEnum } from '@/core/enum/user-role.enum'

export default trafficTicket

const getTrafficTicketRegisterMenu = (actions: { list: any}) => ({
  id: 'traffic-ticket',
  title: 'Infrações',
  icon: 'fa-solid fa-road-circle-exclamation',
  order: 3,
  groupNameKey: 'traffic-ticket',
  route: '/traffic-ticket/list',
  roles: [UserRoleEnum.External, UserRoleEnum.Administrative, UserRoleEnum.Admin],
  items: [
    {
      id: 'list',
      title: 'Infrações',
      icon: 'fa-solid fa-road-circle-exclamation',
      components: [
        {
          id: 'list',
          path: () => import('./traffic-ticket-list.vue'),
        },
      ]
    }
  ],
})
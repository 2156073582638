import { UserRoleEnum } from '@/core/enum/user-role.enum'

const fleets = {
  install(vue: any, parameters: any) {
    const REGISTER_MENU = getRegisterMenu(parameters.routeActions)
    parameters.registerMenu(REGISTER_MENU, parameters)
  }
}

const getRegisterMenu = (actions: { list: any}) => ({
  id: 'fleets',
  title: 'Frotas',
  groupNameKey: 'fleets',
  icon: 'fa-solid fa-car-side',
  order: 2,
  route: '/fleets/list',
  roles: [UserRoleEnum.External, UserRoleEnum.Administrative, UserRoleEnum.Admin],
  items: [
    {
      id: 'list',
      icon: 'fa-solid fa-car-side',
      components: [
        {
          path: () => import('./fleets.vue'),
        },
      ]
    }
  ],
})


export default fleets
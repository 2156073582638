export enum PaymentModalityEnum {
  CashBillet = 1,
  TermBillet = 2,
  RecurrentCreditCard = 3,
  CreditCard = 4,
  Barter = 5,
  PicPay = 6,
  Pix = 7,
  ExchangeWithChange = 8,
  ExchangeWithResidual = 9,
  Other = 10
}

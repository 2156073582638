export enum LotSourceEnum {
    Subscription = 1,
    ExceededKm = 2,
    Ticket = 3,
    TerminationFine = 4,
    ExtendedContract = 5,
    BreakdownRepairs = 6,
    SingleCharge = 7,
}

export function getDescription(status: LotSourceEnum): string {
    switch (status) {
      case LotSourceEnum.Subscription:
        return 'Assinatura';
      case LotSourceEnum.ExceededKm:
        return 'Km Excedido';
      case LotSourceEnum.Ticket:
        return 'Infrações';
      case LotSourceEnum.TerminationFine:
        return 'Multa Rescisória';
      case LotSourceEnum.ExtendedContract:
        return 'Extensão de Contrato';
      case LotSourceEnum.BreakdownRepairs:
        return 'Reparo de avarias';
      case LotSourceEnum.SingleCharge:
        return 'Cobranças avulsas';
      default:
        return "";
    }
  }
export enum ClaimHistoryTypeEnum {
  OpeningProtocol = 1,
  RegistrationInformation = 2,
  SubmissionDocumentation = 3,
  OpeningBudget = 4,
  BudgetAttachment = 5,
  BookingCarRequest = 6,
  BookingCarEdit = 7,
  ChangeStatusBookingCar = 8,
  ChangeStatus = 9,
  ChangeStatusBudget = 10,
  Deletion = 11,
  AutomaticSendingEmail = 12,
  AutomaticSendingSms = 13,
  Internal = 14,
  CustomerContact = 15,
  OfficeContact = 16,
  CancelClaim = 17,
  Finish = 18,
  Responsible = 19
}

import { TrafficTicketPayableStatusEnum } from '@/core/enum/traffic-ticket-payable-status.enum';

export const trafficTicketPayableStatusPipe = (status: TrafficTicketPayableStatusEnum): string => {
  switch (status) {
    case TrafficTicketPayableStatusEnum.Sent:
      return 'Enviado';
    case TrafficTicketPayableStatusEnum.NotSent:
      return 'Não Enviado';
    default:
      return '-';
  }
};
import { format, parse, set, add, isSameDay, parseISO, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export default {
  install: (app) => {
    const locale = ptBR;
    const timeZone = 'America/Sao_Paulo';
    const utcTimeZone = 'UTC';

    
    app.config.globalProperties.$formatDate = (date, formatString) => {
      const regex = /^.*T\d{2}:\d{2}:\d{2}.*$/; 
      let originalDate = parseISO(`${date}`);
      
      if (!isValid(originalDate)) {
        return "-";
      }
    
      if (!formatString) {
        formatString = 'dd/MM/yyyy';
      }
    
      if (regex.test(date)) {
        return format(originalDate, formatString, { locale });
      } else {
        const zonedDate = utcToZonedTime(originalDate, timeZone);
        return format(zonedDate, formatString, { locale });
      }
    };

    app.config.globalProperties.$parseDate = (dateString, formatString, baseDate) => {
      const parsedDate = parse(dateString, formatString, baseDate, { locale, timeZone });
      return utcToZonedTime(parsedDate, timeZone);
    };

    app.config.globalProperties.$setDate = (date, options) => {
      const zonedDate = utcToZonedTime(date, timeZone);
      const newZonedDate = set(zonedDate, options);
      return zonedTimeToUtc(newZonedDate, utcTimeZone);
    };

    app.config.globalProperties.$addDays = (date, amount) => {
      const zonedDate = utcToZonedTime(date, timeZone);
      const newZonedDate = add(zonedDate, { days: amount });
      return zonedTimeToUtc(newZonedDate, utcTimeZone);
    };

    app.config.globalProperties.$isSameDay = (date1, date2) => {
      const zonedDate1 = utcToZonedTime(date1, timeZone);
      const zonedDate2 = utcToZonedTime(date2, timeZone);
      return isSameDay(zonedDate1, zonedDate2);
    };
  },
};
